<template>
  <PrepTasks
      team="components"
      :team-filter="['veg','protein','starch','sauciers','misc']"
      show-late-warnings
      :shorts-only="shortsOnly"
      :hide-shorts="hideShorts"
  />
</template>

<script>

import PrepTasks from '@/components/tasks/PrepTasks';

export default {
  name: "ComponentTasks",
  components: {PrepTasks},
  props: {
    shortsOnly: {type: Boolean, default: false, required: false},
    hideShorts: {type: Boolean, default: false, required: false},
  }
}
</script>

<style scoped>

</style>
