<style scoped>

@page {
  size: 4in 2in;
  margin-top: 0;
  margin-left: 0;
  background-color: #00acc1;
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.box {
  border: 2px solid black;
  height: 40px;
  width: 40px;
  padding: 0;
  font-size: x-large;
}

.circle {
  padding: 0;
  border: 4px solid black;
  height: 70px;
  border-radius: 50%;
  width: 70px;
  font-size: 50px;
  line-height: 60px;
  font-weight: bolder;
}

.circle-smaller {
  padding: 0;
  border: 3px dashed black;
  height: 50px;
  border-radius: 50%;
  width: 50px;
  font-size: 20px;
  line-height: 45px;
}


/* border: 1px solid black; */

.component-label {
  width: 4in !important;
  height: 2in !important;
  color: black;
  overflow: hidden;
}

.circle {
  text-align: center;
  padding: 0;
  margin: 5px;
  border: 3px solid black;
  height: 70px;
  border-radius: 50%;
  min-width: 75px;
  font-size: 20px;
  line-height: 65px;
  font-weight: bolder;
}
</style>
<template>
  <v-container class="component-label page-break full-page-portrait pa-1">
    <v-row dense >
      <v-col cols="3" class="ma-0 pb-0">
        <img :src="qrCodeDataURL" :style="`max-width:${qrCodeSize}px; max-height:${qrCodeSize}px;`"/>
      </v-col>
      <v-col align-self="center" class="ma-0 pb-0 text-center">
        <div style="font-size: xx-large">{{ formatWeightWithUnits(amount) }}</div>
        <span style="font-size:small">{{ date }}</span>
        <span style="font-size: smaller" class="d-print-none"><br/>{{ qrCodeData }}</span>
      </v-col>
      <v-col cols="3" align-self="center" class="ma-0 pb-0 text-right">
        <div class="circle" style="font-size: x-large" v-if="batch">{{ batch }}</div>
      </v-col>
    </v-row>
    <v-row dense class="my-0 py-0">
      <v-col cols="2" class="text-center my-0 py-0" align-self="center">
<!--        <h1 v-if="production_priority">P{{ production_priority }}</h1>-->
      </v-col>
      <v-col align-self="center" class="text-center my-0 py-0">
        <h2><span style="text-decoration: underline">{{ rack }}</span> <span style="font-weight: lighter">{{
            title
          }}</span></h2>
      </v-col>
      <v-col cols="2" class="text-center my-0 py-0" align-self="center" justify="center">
        <template v-if="lineShape==='square'">
          <div class="box" v-if="line">{{ line }}</div>
        </template>
        <template v-else>
          <div class="circle-smaller" v-if="line">{{ line }}</div>
        </template>
      </v-col>
    </v-row>
    <v-row dense class="my-0 py-0 text-center">
      <v-col style="font-size: small; text-overflow: ellipsis; text-wrap: nowrap; overflow: clip">
        {{ portionString }}
        {{ text }}
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import QRCode from "qrcode";
import {formatWeightWithUnits} from "../store/utils";

export default {
  name: "ComponentLabel",
  methods: {formatWeightWithUnits},
  props: {
    code: {type: String, required: true},
    text: {type: String, required: true},
    batch: {type: String, required: true},
    amount: {type: Number, required: true},
    production_priority: {required: false},
    line: {type: String, default: '', required: false},
    title: {type: String, required: true},
    date: {type: String, default: null, required: false},
    lineShape: {type: String, default: null, required: false},
    rack: {type: String, default: null, required: false},
    portionCount: {default: null, required: false},
    portionSize: {default: null, required: false}
  },
  async mounted() {
    this.qrCodeDataURL = await QRCode.toDataURL(this.qrCodeData, {
      type: 'svg',
      width: this.qrCodeSize,
      height: this.qrCodeSize
    });
  },
  data() {
    return {
      qrCodeSize: 100,
      qrCodeDataURL: null
    }
  },
  computed: {
    portionString() {
      if (this.portionCount) {
        return `${Object.values(this.portionCount).reduce((sum, i) => sum + i, 0)}CT`;
      } else {
        return '';
      }
    },
    qrCodeData() {
      return `${this.batch}:${this.line || 'M'}:${this.code}`;
    }
  }
}
</script>


